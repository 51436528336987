<template>
  <div>
    <div class="flex justify-between pl-12 pt-10 mb-1">
      <div
        id="gameId"
        class="flex space-x-3 font-extrabold text-6xl 2xl:text-7xl 3xl:text-8xl 4xl:text-8xl"
      >
        <div class="pt-2 2xl:pt-3">
          <!-- <p
            id="draw"
            class="bg-gradient-to-b from-time via-time to-black text-transparent bg-clip-text"
          >
            DRAW
          </p> -->
          <img
            class="h-14 2xl:h-14 3xl:h-22 4xl:h-22 pt-1 mb-1 object-contain"
            src="../../../public/New/Draw.svg"
            alt=""
          />
        </div>
        <div
          @click="logout()"
          class="text-white m-0 hidden xl:flex 2xl:hidden 2xl:pt-2 4xl:pt-2"
        >
          {{ paddedValue }}
          <!-- <p class="text-xs">xl</p> -->
        </div>
        <div
          @click="logout()"
          class="text-white m-0 hidden 2xl:block 3xl:hidden 2xl:pt-2 4xl:pt-2"
        >
          {{ paddedValue }}
        </div>
        <div
          @click="logout()"
          class="text-white m-0 hidden 3xl:block 4xl:hidden"
        >
          {{ paddedValue }}
        </div>
        <div
          @click="logout()"
          class="text-white m-0 hidden 4xl:block 5xl:hidden 2xl:pt-2 4xl:pt-2"
        >
          {{ paddedValue }}
        </div>
      </div>
      <div
        :style="{
          opacity: num >= 1 && num <= 40 ? '100%' : '0%',
        }"
        class="rounded-md text-center pr-8"
      >
        <div class="mt-2 3xl:mt-3">
          <img
            class="object-contain h-14 2xl:h-14 3xl:h-18 4xl:h-22"
            src="../../../public/New/heads.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event/EventBus";
import { mapState } from "vuex";
export default {
  computed: {
    paddedValue() {
      const subtractedGameId = this.gameId - 1;
      const value = subtractedGameId.toString().padStart(3, "0");
      return value;
    },
    ...mapState("Game", ["gameId"]),
    ...mapState("Game", ["num"]),
  },
  beforeDestroy() {
    EventBus.$off("stopService");
    // EventBus.$off("runDrawingFunctions");
  },
  methods: {
    async logout() {
      EventBus.$emit("stopService", "logout");
      const routeToHome = () => {
        localStorage.clear();
        this.$store.dispatch("Authentication/logOut");
        this.$router.push({
          path: "/",
        });
      };
      window.setTimeout(routeToHome, 1000);
    },
  },
};
</script>

<style>
#gameId {
  font-family: "gameId";
}
@font-face {
  font-family: "gameId";
  src: url("../../../public/fonts/Gunar W00 Heavy.ttf") format("woff2");
}
#draw {
  font-family: "draw";
}
@font-face {
  font-family: "draw";
  src: url("../../../public/fonts/ZawyaPro-WideBold.otf") format("woff2");
}
</style>
