<template>
  <div class="4xl:mt-6" id="numbers">
    <carousel
      :class="carouselClass"
      class="flex justify-center lg:mt-8 3xl:mt-0"
      :per-page="1"
      :centerMode="true"
      :mouse-drag="false"
      :autoplay="true"
      :loop="true"
      :paginationEnabled="false"
      :autoplayTimeout="5000"
      :reverse="false"
      :wrap-around="true"
    >
      <slide :class="hits">
        <div class="mx-4 text-center uppercase text-white">Play</div>
        <div class="flex justify-center space-x-2">
          <div class="mx-4 text-center text-thin uppercase text-white">The</div>
          <div class="text-center uppercase text-white">
            <img
              :class="widthPic"
              class="mt-1"
              src="../../public/New/pick10.svg"
              alt=""
              srcset=""
            />
          </div>
          <div class="mx-4 text-center uppercase text-white">Game</div>
        </div>

        <div
          class="mx-4 mt-8 flex justify-center space-x-3 text-center text-white"
        >
          <p>Get</p>
          <p class="text-Numbers">10</p>
          <p>numbers</p>
        </div>
        <div class="mx-4 text-center text-white">correct, and</div>
        <div class="mx-4 text-center text-white">win the</div>
        <div class="mx-4 text-center text-white">
          <div class="flex justify-center">
            <div class="text-center mt-2 uppercase text-white">
              <img
                :class="width"
                src="../../public/New/pick10.svg"
                alt=""
                srcset=""
              />
            </div>
            <div class="mx-4 text-center uppercase text-white">JACKPOT</div>
          </div>
        </div>
      </slide>
      <!-- contents -->
      <slide
        v-for="(index, i) in PicksData"
        :key="i"
        class="text-center uppercase text-white font-bold"
      >
        <div class="text-Numbers mb-3">
          <div class="flex justify-center">
            <!-- <div class="text-center mt-2 uppercase text-white">
              <img
                :class="widthPic"
                src="../../public/New/pick.svg"
                alt=""
                srcset=""
              />
            </div> -->
            <div class="mx-4 text-center uppercase">
              <img
                v-if="index.name == 1"
                :class="widthPic"
                src="../../public/Pick/pick1.svg"
                alt=""
                srcset=""
              />
              <img
                v-if="index.name == 2"
                :class="widthPic"
                src="../../public/Pick/pick2.svg"
                alt=""
                srcset=""
              />
              <img
                v-if="index.name == 3"
                :class="widthPic"
                src="../../public/Pick/pick3.svg"
                alt=""
                srcset=""
              />
              <img
                v-if="index.name == 4"
                :class="widthPic"
                src="../../public/Pick/pick4.svg"
                alt=""
                srcset=""
              />
              <img
                v-if="index.name == 5"
                :class="widthPic"
                src="../../public/Pick/pick5.svg"
                alt=""
                srcset=""
              />
              <img
                v-if="index.name == 6"
                :class="widthPic"
                src="../../public/Pick/pick6.svg"
                alt=""
                srcset=""
              />
              <img
                v-if="index.name == 7"
                :class="widthPic"
                src="../../public/Pick/pick7.svg"
                alt=""
                srcset=""
              />
              <img
                v-if="index.name == 8"
                :class="widthPic"
                src="../../public/Pick/pick8.svg"
                alt=""
                srcset=""
              />
              <img
                v-if="index.name == 9"
                :class="widthPic"
                src="../../public/Pick/pick9.svg"
                alt=""
                srcset=""
              />
              <img
                v-if="index.name == 10"
                :class="widthPic"
                src="../../public/Pick/pick10.svg"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
        <div class="flex justify-center space-x-20">
          <div class="">
            <p :class="hits" class="text-YellowKeno mb-2">Hits</p>
            <p
              v-for="(g, index) in index.HITS"
              :class="items"
              class="text-white text-start"
            >
              {{ g }}
            </p>
          </div>
          <div>
            <p :class="hits" class="text-YellowKeno mb-2">Wins</p>
            <p
              v-for="(l, index) in index.WIN"
              :key="index"
              :class="items"
              class="text-white text-start"
            >
              {{ l.toLocaleString() }}
            </p>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import PicksData from "../../public/Odds.json";
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },

  data() {
    return {
      PicksData,
    };
  },
  props: {
    carouselClass: {
      type: String,
    },
    width: {
      type: String,
    },
    widthPic: {
      type: String,
    },
    // carouselNumber: {
    //   type: String,
    // },

    hits: {
      type: String,
    },
    items: {
      type: String,
    },
  },
};
</script>

<style></style>
