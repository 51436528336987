<template>
  <div class="text-center mt-3">
    <p
      id="numFont"
      v-if="timerDone || countdownTime == '59:59'"
      class="bg-gradient-to-b from-time via-time to-black text-transparent bg-clip-text text-center"
      :class="betClosedClass"
    >
      Bet Closed
    </p>
    <!-- mx-28 2xl:ml-32 3xl:ml-36 4xl:ml-40 -->
    <p
      id="counter"
      v-if="countdownTime !== '59:59'"
      class="bg-gradient-to-b from-time via-time to-black text-transparent bg-clip-text xl:ml-28 2xl:ml-36 3xl:ml-36 4xl:ml-48"
      :class="timeClass"
    >
      <!-- :class="[timeClass, { box: flash }]" -->
      {{ countdownTime }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    timeClass: {
      required: true,
      type: String,
    },
    betClosedClass: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState("BaseCountDown", ["formattedTime"]),
    ...mapState("BaseCountDown", ["timerDone"]),
    ...mapState("BaseCountDown", ["flash"]),
    countdownTime() {
      return this.formattedTime;
    },
  },
};
</script>
<style>
#counter {
  font-family: "counter";
}
@font-face {
  font-family: "counter";
  src: url("../../../public/fonts/eurostile_next_lt_pro_semibold.ttf")
    format("woff2");
}
.box {
  animation: flash 1.2s linear infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
