<template>
  <div
    id="numFont"
    class="w-screen h-screen bg-gradient-to-l from-D1 via-D2 to-D3 items-center flex justify-center b"
  >
    <div class="">
      <div class="flex">
        <img
          class="w-32 h-32 object-contain"
          src="../../../public/logo.svg"
          alt=""
        />
        <div
          class="text-9xl bg-gradient-to-br from-redo via-7 to-YellowKeno text-transparent bg-clip-text"
        >
          KenoPro
        </div>
      </div>
      <div
        class="py-10 text-center bg-gradient-to-br from-YellowKeno via-7 to-YellowKeno text-transparent bg-clip-text"
      >
        <div v-if="!complete" class="text-4xl">Loading Assets!</div>
        <div v-if="complete" class="text-4xl">Loading Complete!</div>
        <Spinner v-if="!complete" class="" />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
export default {
  components: { Spinner },
  props: {
    complete: {
      type: Boolean,
      required: true,
    },
    // assets: {
    //   required: true,
    // },
  },
};
</script>

<style></style>
