<template>
  <div class="text-black flex justify-center bg-white w-screen h-screen">
    <div>
      <div class="font-bold">
        <h1 class="flex justify-center text-5xl">Opps!</h1>
        <h3 class="text-xl font-bold m-3 flex justify-center">Not Found</h3>
      </div>
      <img
        src="../assets/61.svg"
        alt=""
        class=""
        style="width: 1; height: 390px"
      />
      <h3 class="text-xl flex justify-center">
        The Page you are looking for is not here!
      </h3>

      <div class="flex justify-center cursor-pointer">
        <BaseButton
          class="text-base m-5 mx-1 flex justify-center w-48 transition duration-500 ease-in-out bg-blue-600 transform hover:-translate-y-1 hover:scale-110 hover:bg-blue-00 text-white font-bold py-2 px-4 rounded"
          @click="$router.push({ path: '/' })"
          >Back to KenoPro</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
