<template>
  <div id="Myfont" class="">
    <div class="w-full max-w-4xl">
      <div class="p-2">
        <div>
          <div
            class="px-2 rounded-md flex justify-between space-x-4 m-1"
            :class="{
              'bg-red-500': notification.type === 'error',
              'bg-green-500': notification.type === 'success',
              'bg-teal-500': notification.type === 'logout',
              'bg-gray-500': notification.type === 'status',
            }"
          >
            <div class="flex space-x-3">
              <div class="flex">
                <div class="">
                  <p
                    v-if="notification.type !== 'logout'"
                    class="text-white text-base text-center py-3 items-center first-letter:uppercase px-4"
                    :class="{ 'text-white': notification.type === 'logout' }"
                  >
                    {{ notification.type }}
                  </p>
                  <p
                    v-if="notification.type === 'logout'"
                    class="text-white text-base text-center py-3 items-center first-letter:uppercase px-4"
                    :class="{ 'text-white': notification.type === 'logout' }"
                  >
                    success
                  </p>
                </div>

                <p
                  class="border"
                  :class="{
                    'border-red-600': notification.type === 'error',
                    'border-green-600': notification.type === 'success',
                    'border-teal-600': notification.type === 'logout',
                    'border-gray-600': notification.type === 'status',
                  }"
                ></p>
              </div>

              <p
                class="text-white text-sm py-3 w-full"
                :class="{ 'text-white': notification.type === 'logout' }"
              >
                {{ notification.message }}
              </p>
            </div>
            <div class="pl-2 flex justify-center">
              <img
                class="cursor-pointer"
                @click="removeNotification(notification)"
                src="../../assets/close.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      timeout: null,
    };
  },
  mounted() {
    this.timeout = setTimeout(
      () => this.removeNotification(this.notification),
      5000
    );
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: { ...mapActions("notification", { removeNotification: "remove" }) },
};
</script>

<style scoped>
.notfication-bar {
  margin: 1em 0 1em;
}
#Myfont {
  font-family: PTSans;
}
@font-face {
  font-family: "PTSans";
  src: local("PTSans"),
    url("../../assets/Fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 100;
  font-size: xx-large;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}
.fade-leave {
  opacity: 0;
}

/* ............. */

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 1.5s ease;
}
.slide-fade-leave {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
