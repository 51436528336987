<template>
  <div class="" style="">
    <div class="bg-blue-900" style="height:170px">
      <div class="flex justify-center">
        <p class="text-4xl uppercase font- font-extrabold   text-white mt-12 ">Waft Chat</p>
      </div>
      <div class="flex justify-center">
        <p class="text-base font-mono font-bold  text-blue-400 mt-3 ">Fast, secure and Lightweight</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
