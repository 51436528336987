<template>
  <div
    id="numFont"
    class="w-screen h-screen bg-gradient-to-l from-D1 via-D2 to-D3 items-center flex justify-center b"
  >
    <div class="">
      <div class="flex">
        <img
          class="w-32 h-32 object-contain"
          src="../../../public/logo.svg"
          alt=""
        />
        <div
          class="text-9xl bg-gradient-to-br from-redo via-7 to-YellowKeno text-transparent bg-clip-text"
        >
          KenoPro
        </div>
      </div>
      <div
        class="py-10 text-center bg-gradient-to-br from-YellowKeno via-7 to-YellowKeno text-transparent bg-clip-text"
      >
        <div class="text-4xl mb-5">Loading Assets Failed!</div>
        <!-- <Spinner class="" /> -->
        <BaseButton
          autofocus
          @keypress.enter="fetchAgain()"
          @click="fetchAgain()"
          buttonClass="p-3 text-xl text-white rounded-md bg-gradient-to-r from-D3 via-7 to-7"
          >Load Again</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    fetchAgain() {
      this.$parent.fetchAssets();
    },
  },
};
</script>

<style></style>
