/* eslint-disable */
import Vue from "vue";
import AxiosService from "../../services/AxiosServices";
import ErrorHandler from "../../services/ErrorHandler";
import router from "@/router";
import { EventBus } from "@/event/EventBus";
export const namespaced = true;
export const state = {
  status: "Timing",
  index: 0,
  num: null,
  gameId: "",
  animate: null,
  inMiddleGame: null,
  forStyle: [],
  luckyNumbers: [],
  animateNumbers: false,
  afterDraw: [],
  startingTime: "",
  endingTime: "",
  entranceAnimation: "",
  animateCards: false,
  gameStatus: "",
};

export const mutations = {
  updateTime(state, { starting, ending }) {
    state.startingTime = starting;
    state.endingTime = ending;
  },
  updateNum(state, newNumber) {
    state.num = newNumber;
  },
  updateGameStatus(state, newGameStatus) {
    state.gameStatus = newGameStatus;
  },
  updateStatus(state, newStatus) {
    state.status = newStatus;
  },
  updateGameId(state, newGameId) {
    state.gameId = newGameId;
  },
  updateIndex(state) {
    state.index++;
  },
  updateIndexFromMiddle(state, payload) {
    state.index = payload;
  },
  pushActiveIndexNum(state, newIndexNum) {
    state.forStyle.push(newIndexNum);
    state.afterDraw.push(newIndexNum);
  },
  updateLuckyNumbers(state, newLuckyNum) {
    state.luckyNumbers = newLuckyNum;
  },
  updateForStyleNumbers(state, newForStyleNum) {
    state.forStyle = newForStyleNum;
  },
  clearState(state, type) {
    clearInterval(state.animate);
    clearInterval(state.inMiddleGame);
    state.animate = null;
    state.inMiddleGame = null;
    state.index = 0;
    state.num = null;
    if (type == "logout") {
      state.startingTime = "";
      state.ending = "";
      state.status = "";
      state.gameId = "";
    } else if (type == "newDrawnNumbers" || type == "existingDrawnNumbers") {
      state.forStyle = [];
      state.afterDraw = [];
    }
  },
  updateEntrance(state, mode) {
    state.entranceAnimation = mode;
  },
  updateAnimateCards(state, mode) {
    state.animateNumbers = mode;
  },
};
export const actions = {
  settingUp({ state, dispatch, commit }, message) {
    // if (message) {
    // console.log("mess: ", message);
    const payload = message.message;
    commit("updateGameStatus", payload.status);
    commit("updateTime", {
      starting: message.createdAt,
      ending: payload.nextDate,
    });
    state.gameStatus = payload.status;
    if (payload.status == "newGame" || payload.status == "existingNewGame") {
      // console.log("here 1: ", payload.customId);
      commit("updateStatus", "Timing");
      commit("updateGameId", payload.customId);
      EventBus.$emit("runTimerFunctions");
      dispatch("getLuckyNumbers");
    } else if (
      payload.status == "newDrawnNumbers" ||
      payload.status == "existingDrawnNumbers"
    ) {
      commit("updateGameId", payload.nextGameId);
      // console.log("here 2: ", payload.customId);
      // console.log("in new drawn and exi drawn");
      EventBus.$emit("runDrawingFunctions");
      if (payload.status == "newDrawnNumbers") {
        commit("updateStatus", "Video");
      } else {
        commit("updateStatus", "Drawing");
      }
    }
    // }
  },
  async getLuckyNumbers({ state, dispatch, commit }) {
    try {
      if (state.gameStatus == "newDrawnNumbers") {
        // commit("updateStatus", "Video");
        commit("clearState", "newDrawnNumbers");
        const numbers = await AxiosService.getLuckyNumbers(state.gameId - 1);
        state.forStyle = [];
        commit("updateLuckyNumbers", numbers.data.doc.numbers_That_Won);
        // dispatch("startAnimation");
        return "time to animate";
      } else if (state.gameStatus == "existingDrawnNumbers") {
        // commit("updateStatus", "Drawing");
        commit("clearState", "existingDrawnNumbers");
        // state.forStyle = [];
        const numbers = await AxiosService.getLuckyNumbers(state.gameId - 1);
        commit("updateLuckyNumbers", numbers.data.doc.numbers_That_Won);
        // return "in middle";
        // dispatch("inMiddleGameAnimation");
      } else if (
        state.gameStatus == "newGame" ||
        state.gameStatus == "existingNewGame"
      ) {
        if (state.forStyle.length <= 0) {
          const numbers = await AxiosService.getLuckyNumbers(state.gameId - 1);
          commit("updateForStyleNumbers", numbers.data.doc.numbers_That_Won);
        }
      }
    } catch (error) {
      ErrorHandler(error);
    }
  },
  startAnimation({ state, dispatch, commit }) {
    setTimeout(() => {
      commit("updateStatus", "Drawing");
      // commit("updateAnimateCards", true);
      // state.animateNumbers = true;
      // setTimeout(() => {
      EventBus.$emit("animateCards");
      setTimeout(() => {
        state.animate = setInterval(() => {
          if (state.index < state.luckyNumbers.length) {
            commit("updateNum", state.luckyNumbers[state.index]);
            // state.num = state.luckyNumbers[state.index];
            EventBus.$emit("randomRotationValue");
            // state.entranceAnimation = "entrance";
            commit("updateEntrance", "entrance");
            EventBus.$emit("playClickSound");
            EventBus.$emit("playNumSound");
            commit("pushActiveIndexNum", state.luckyNumbers[state.index]);
            // state.forStyle.push(state.luckyNumbers[state.index]);
            // state.afterDraw.push(state.luckyNumbers[state.index]);
            setTimeout(() => {
              commit("updateEntrance", "exit");
            }, 2200);
          } else {
            setTimeout(() => {
              // state.status = "lastGames";
              commit("updateStatus", "lastGames");
              EventBus.$emit("playTimerSong");
            }, 2000);
            setTimeout(() => {
              dispatch("checkConnection");
            }, 17000);
            // commit("updateAnimateCards", false);
            // console.log("finished:", state.status);
            // state.animateNumbers = false;
            clearInterval(state.animate);
          }
          commit("updateIndex");
          // state.index++;
        }, 2500);
      }, 1000);
      // }, 500);

      // state.animateCards();
    }, 3500);
  },
  inMiddleGameAnimation({ state, dispatch, commit }) {
    commit("updateStatus", "Drawing");
    // console.log("in middle game animation");
    // state.status = "Drawing";

    if (state.gameStatus === "existingDrawnNumbers") {
      let timeDifference = Number(
        (state.endingTime - state.startingTime).toFixed(0)
      );
      if (timeDifference > 67000) {
        setTimeout(() => {
          state.inMiddleGame = setInterval(() => {
            if (state.index < state.luckyNumbers.length) {
              commit("updateNum", state.luckyNumbers[state.index]);
              // state.num = state.luckyNumbers[state.index];
              EventBus.$emit("randomRotationValue");
              // dispatch("randomRotationValue");
              commit("updateEntrance", "entrance");
              // state.entranceAnimation = "entrance";
              EventBus.$emit("playClickSound");
              EventBus.$emit("playNumSound");
              commit("pushActiveIndexNum", state.luckyNumbers[state.index]);
              // state.forStyle.push(state.luckyNumbers[state.index]);
              // state.afterDraw.push(state.luckyNumbers[state.index]);
              setTimeout(() => {
                commit("updateEntrance", "exit");
                // state.entranceAnimation = "exit";
              }, 2200);
            } else {
              setTimeout(() => {
                setTimeout(() => {
                  dispatch("checkConnection");
                }, 17000);
                // state.status = "lastGames";
                commit("updateStatus", "lastGames");
                EventBus.$emit("playTimerSong");
                // state.stopShufflingSound();
              }, 2000);
              clearInterval(state.inMiddleGame);
            }
            commit("updateIndex");
            // state.index++;
          }, 2500);
        }, 1000);
      } else {
        let newTimeDifference = Number((timeDifference - 16000).toFixed(0));
        let ballsLeft = Number((newTimeDifference / 2500).toFixed(0));
        let currentNum = state.luckyNumbers.length - ballsLeft;
        let lastNum = currentNum + 1;
        commit("updateIndexFromMiddle", lastNum);
        for (let i = 0; i < state.index; i++) {
          // commit("pushActiveIndexNum", state.luckyNumbers[state.index]);
          state.forStyle.push(state.luckyNumbers[i]);
          state.afterDraw.push(state.luckyNumbers[i]);
        }
        // commit("updateIndexFromMiddle", lastNum);

        state.inMiddleGame = setInterval(() => {
          if (state.index < state.luckyNumbers.length) {
            // state.num = state.luckyNumbers[lastNum];
            commit("updateNum", state.luckyNumbers[state.index]);
            EventBus.$emit("randomRotationValue");
            commit("updateEntrance", "entrance");
            // state.entranceAnimation = "entrance";
            EventBus.$emit("playClickSound");
            EventBus.$emit("playNumSound");
            commit("pushActiveIndexNum", state.luckyNumbers[state.index]);

            // state.forStyle.push(state.luckyNumbers[lastNum]);
            // state.afterDraw.push(state.luckyNumbers[lastNum]);
            setTimeout(() => {
              commit("updateEntrance", "exit");
              // state.entranceAnimation = "exit";
            }, 2200);
          } else {
            setTimeout(() => {
              // state.status = "lastGames";
              commit("updateStatus", "lastGames");
              EventBus.$emit("playTimerSong");
            }, 2000);
            setTimeout(() => {
              dispatch("checkConnection");
            }, 17000);
            // state.entranceAnimation = "exit";
            // state.setTimeForCountDown();
            clearInterval(state.inMiddleGame);
          }
          commit("updateIndex");
        }, 2500);
      }
    } else {
      // console.log("unknown game status");
    }
  },
  checkConnection() {
    if (navigator.onLine) {
      return true;
    } else {
      EventBus.$emit("stopService");
      dispatch("Authentication/setTrue");
    }
  },
  randomRotationValue({ state }) {
    state.randomRotation =
      state.rotations[Math.floor(Math.random() * state.rotations.length)];
  },
  eventCheck({ commit }, message) {
    EventBus.$emit("testing-event");
  },
};
export const getters = {};

export default {};
