<template>
  <div id="numFo" class="h-screen common timer">
    <div v-if="!failed && next && !assets && !noConnection">
      <div
        id="numFont"
        class="w-screen h-screen bg-gradient-to-l from-D1 via-D2 to-D3 items-center flex justify-center b"
      >
        <div class="">
          <div class="flex">
            <img
              class="w-32 h-32 object-contain"
              src="../../../public/logo.svg"
              alt=""
            />
            <div
              class="text-9xl bg-gradient-to-br from-redo via-7 to-YellowKeno text-transparent bg-clip-text"
            >
              KenoPro
            </div>
          </div>
          <div
            class="py-10 text-center bg-gradient-to-br from-YellowKeno via-7 to-YellowKeno text-transparent bg-clip-text"
          >
            <div class="text-4xl mb-5">Complete!</div>
            <BaseButton
              autofocus
              @keypress.enter="Continue()"
              @click="Continue()"
              buttonClass="p-3 text-xl text-white rounded-md bg-gradient-to-r from-D3 via-7 to-7"
              >Get Started</BaseButton
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!failed && assets && !next && !noConnection"
      class="h-screen svg w-screen"
    >
      <!-- <kenoV5 /> -->
      <!-- <keno8 /> -->
      <!-- <keno9 /> -->
      <keno7 />
    </div>
    <div v-if="!imageCashed && !videoCached && !noConnection">
      <loading-assets
        v-if="!assets && !failed && !next"
        :complete="complete"
        :assets="assets"
      />
      <Error v-if="failed" />
    </div>
    <div v-if="noConnection">
      <NetworkIssue />
    </div>
  </div>
</template>
<script>
import NetworkIssue from "@/Error/NetworkIssue.vue";
import Spinner from "@/components/Spinner.vue";
import LoadingAssets from "@/components/child/LoadingAssets.vue";
import Error from "@/components/child/ErrorLoadingAssets.vue";
import ErrorHandler from "@/services/ErrorHandler";
import AxiosServices from "@/services/AxiosServices";
import { mapState } from "vuex";
import keno7 from "./KenoProV7.vue";
import keno8 from "./KenoProV8.vue";
import keno9 from "./KenoProV9.vue";
export default {
  components: {
    Spinner,
    LoadingAssets,
    Error,
    NetworkIssue,
    keno7,
    keno8,
    keno9,
  },
  data() {
    return {
      assets: false,
      failed: false,
      complete: false,
      imageCashed: false,
      videoCached: false,
      audioCached: false,

      next: false,
    };
  },

  created() {
    AxiosServices.getMe()
      .then((res) => {
        if (res) {
          this.$store.state.Authentication.noConnection = false;
          this.fetchAssets();
        }
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  },
  mounted() {},
  computed: {
    ...mapState("Authentication", ["noConnection"]),
  },
  methods: {
    Continue() {
      this.next = false;
      this.assets = true;
    },
    async fetchAssets() {
      this.failed = false;
      try {
        let image = await this.$store.dispatch("LoadImages/preloadImages");
        // let audio = await this.$store.dispatch("LoadAudios/preloadAudio");
        let video = await this.$store.dispatch("LoadAssets/preloadVideo");
        // console.log("image:", image);
        // // console.log("audio:", audio);
        // console.log("video:", video);
        if (image && video) {
          // console.log("image Cached:");
          // console.log("video cached:", video);
          this.failed = false;
          this.complete = false;
          this.next = true;
          // this.assets = true;
        } else {
          this.failed = true;
          this.noConnection = true;
        }
        // this.imageCashed = localStorage.getItem("imagesCashed");
        // this.videoCached = localStorage.getItem("videoCashed");
        // this.audioCached = localStorage.getItem("audioCashed");
        // if (!this.imageCashed) {
        //   let image = await this.$store.dispatch("LoadImages/preloadImages");
        //   console.log("images: ", image);
        //   if (image) {
        //     localStorage.setItem("imagesCashed", true);
        //   } else {
        //     this.assets = false;
        //     this.failed = true;
        //     this.complete = false;
        //   }
        // }
        // if (!this.audioCached) {
        //   console.log("vid");
        //   let audio = await this.$store.dispatch("LoadAudios/preloadAudio");
        //   console.log("audio: ", audio);
        //   if (audio) {
        //     localStorage.setItem("audioCashed", true);
        //   } else {
        //     this.assets = false;
        //     this.failed = true;
        //   }
        // }
        // if (!this.videoCached) {
        //   console.log("vid");
        //   let video = await this.$store.dispatch("LoadAssets/preloadVideo");
        //   console.log("video: ", video);
        //   if (video) {
        //     localStorage.setItem("videoCashed", true);
        //     this.failed = false;
        //     this.complete = false;
        //     this.assets = true;
        //     this.$router.push({ name: "KenoPro" });
        //   } else {
        //     this.assets = false;
        //     this.failed = true;
        //   }
        // }
      } catch (error) {
        this.failed = true;
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
