import Vue from "vue";

export const namespaced = true;

export const state = {
  imageUrls: [],
};

export const mutations = {
  setImageUrls(state, urls) {
    state.imageUrls = urls;
  },
};

export const actions = {
  async preloadImages({ commit, dispatch }) {
    const cacheName = "image-cache";
    const imageUrls = [];

    for (let i = 1; i <= 80; i++) {
      const imageUrl = require(`../../../public/exports/${i}.svg`);

      if ("caches" in window) {
        try {
          const cache = await window.caches.open(cacheName);
          const response = await cache.match(imageUrl);

          if (response) {
            const blobUrl = URL.createObjectURL(await response.blob());

            imageUrls.push(blobUrl);
            // console.log(`Image ${i} found in cache!`);
          } else {
            try {
              const imageBlobUrl = await dispatch("loadImageFromNetwork", {
                imageUrl,
                cache,
              });

              imageUrls.push(imageBlobUrl);
            } catch (error) {
              // console.error(`Error fetching image ${imageUrl}:`, error);
              imageUrls.push(null);
            }
          }
        } catch (error) {
          console.error(`Error opening cache for image ${i}:`, error);
          throw error;
        }
      } else {
        try {
          const imageBlobUrl = await dispatch("loadImageFromNetwork", {
            imageUrl,
          });

          imageUrls.push(imageBlobUrl);
        } catch (error) {
          // console.error(`Error fetching image ${imageUrl}:`, error);
          imageUrls.push(null);
        }
      }
    }

    commit("setImageUrls", imageUrls);
    return imageUrls;
  },

  async loadImageFromNetwork({ commit }, { imageUrl, cache }) {
    try {
      const response = await fetch(imageUrl);

      if (response.ok) {
        const imageBlob = await response.blob();
        const blobUrl = URL.createObjectURL(imageBlob);

        if (cache) {
          const contentType = response.headers.get("Content-Type");
          await cache.put(
            imageUrl,
            new Response(imageBlob, {
              status: response.status,
              statusText: response.statusText,
              headers: { "Content-Type": contentType },
            })
          );
        }

        return blobUrl;
      } else {
        throw new Error(`Failed to fetch image ${imageUrl}`);
      }
    } catch (error) {
      throw error;
    }
  },
};

export const getters = {};

export default {};
