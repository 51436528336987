<template>
  <div
    class="w-screen h-screen bg-gradient-to-l from-D1 via-D2 to-D3 items-center flex justify-center b"
  >
    <div>
      <div class="flex">
        <img
          class="w-32 h-32 object-contain"
          src="../../../public/logo.svg"
          alt=""
        />
        <div
          class="text-9xl bg-gradient-to-br from-redo via-7 to-YellowKeno text-transparent bg-clip-text"
        >
          KenoPro
        </div>
      </div>
      <div
        class="py-10 text-center bg-gradient-to-br from-YellowKeno via-7 to-YellowKeno text-transparent bg-clip-text"
      >
        <div class="text-4xl">Establishing Connection!</div>
        <Spinner class="" />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../Spinner.vue";
export default {
  components: {
    Spinner,
  },
};
</script>

<style></style>
