<template>
  <div
    id="Myfont"
    class="h-screen bg-gradient-to-br from-YellowKeno via-G1 to-G3 text-white"
  >
    <div class="flex justify-center">
      <div
        class="my-16 py-10 bg-red-900 shadow-xs p-3"
        style="border-radius: 5px"
      >
        <div class="p-2">
          <div class="flex justify-center">
            <img class="h-24" src="../../../public/logo.svg" alt="" />
          </div>

          <div class="m-5 flex justify-center space-y-4">
            <p class="text-2xl text-redo">KenoPro</p>
            <!-- <img src="../../p/Lock screen.svg" class="h-24 w-24 justify-start" alt="" /> -->
          </div>

          <form
            @submit.prevent="onSubmit"
            style="width: 350px"
            class="mt-6 space-y-5 max-w-2xl"
          >
            <input
              @keypress.enter="onSubmit()"
              placeholder="Email"
              v-model.trim="formdata.email"
              @blur="$v.formdata.email.$touch()"
              class="bg-yellow-200 text-black placeholder-redo outline-none w-full p-2 rounded"
            />

            <div v-if="$v.formdata.email.$error">
              <p
                class="text-xs text-red-600"
                v-if="!$v.formdata.email.required"
                id="error_label"
              >
                email is required!
              </p>
            </div>
            <div v-if="$v.formdata.email.$error">
              <p
                class="text-xs text-red-600"
                v-if="!$v.formdata.email.numeric"
                id="error_label"
              >
                invalid email!
              </p>
            </div>
            <div>
              <label for="password" class="sr-only text-redo">Password</label>

              <div class="relative">
                <input
                  @keypress.enter="onSubmit()"
                  :type="show ? 'text' : 'password'"
                  class="bg-yellow-200 text-black placeholder-redo outline-none w-full p-2 rounded"
                  placeholder="password"
                  @blur="$v.formdata.password.$touch()"
                  v-model.trim="formdata.password"
                />
                <div v-if="$v.formdata.password.$error">
                  <p
                    class="text-xs text-red-600"
                    v-if="!$v.formdata.password.required"
                    id="error_label"
                  >
                    password is required!
                  </p>
                </div>

                <span
                  :class="{
                    hidden: show,
                    block: !show,
                    'mb-5': $v.formdata.password.$error,
                  }"
                  class="absolute inset-y-0 left-72 ml-2 grid place-content-center px-4"
                >
                  <svg
                    @click="show = !show"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                </span>

                <span
                  :class="{
                    block: show,
                    hidden: !show,
                    'mb-5': $v.formdata.password.$error,
                  }"
                  class="absolute inset-y-0 left-72 ml-2 grid place-content-center px-4"
                >
                  <img
                    @click="show = !show"
                    class="text-1 h-8 w-8"
                    src="../../assets/eyeslashsvgrepocom.svg"
                    alt=""
                    srcset=""
                  />
                  <!-- <i
                    @click="show = !show"
                    class="text-1 mt-1 h-8 w-8 fa fa-eye-slash"
                  ></i> -->
                  <!-- <svg
                    @click="show = !show"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg> -->
                </span>
              </div>
            </div>
            <!-- <input
              :type="show ? 'text' : 'password'"
              placeholder="password"
              @blur="$v.formdata.password.$touch()"
              v-model.trim="formdata.password"
              class="bg-darkbg outline-none w-full p-2 rounded"
            />

            <div v-if="$v.formdata.password.$error">
              <p
                class="text-xs text-red-600"
                v-if="!$v.formdata.password.required"
                id="error_label"
              >
                password is required!
              </p>
            </div> -->
          </form>
          <!-- <div class="flex justify-end space-x-6 mt-4">
            <div>
              <a
                @click="$router.push({ name: 'forgot-password' })"
                id="cursorHand"
                class="inline-block align-baseline text-sm underline cursor-pointer"
              >
                forgot password!
              </a>
            </div>
          </div> -->
          <div class="flex justify-center space-x-3 mt-5">
            <button
              :disabled="loginRequestSent"
              type="submit"
              Class="bg-redo flex justify-center space-x-3 py-1 w-full text-white"
              @click="onSubmit()"
            >
              <svg
                v-if="loginRequestSent"
                class="h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              LogIn
            </button>
          </div>
          <p v-if="error" class="text-xs text-red-700">
            please fill out the forms correctly!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthenticationServices from "../../services/AuthenticationServices";
import { required, email } from "vuelidate/lib/validators";
export default {
  components: {},
  data() {
    return {
      error: false,
      status: false,
      show: false,
      loginRequestSent: false,
      formdata: {
        email: "",
        password: "",
      },
    };
  },

  validations: {
    formdata: {
      email: { required, email },
      password: { required },
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.loginRequestSent) {
          this.loginRequestSent = true;
          await this.$store
            .dispatch("Authentication/signIn", this.formdata)
            .then((response) => {
              // console.log("response", response);
              if (response && !response.error) {
                this.$router.push({
                  name: "kenoPro",
                });
                // const notification = {
                //   type: "success",
                //   message: "you have successfully logged in!",
                // };
                // setTimeout(() => {
                //   this.$store.dispatch("notification/add", notification);
                // }, 1000);

                this.loginRequestSent = false;
              } else {
                this.loginRequestSent = false;
              }
            });
        } else {
          // console.log("error");
          this.loginRequestSent = false;
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
      }

      this.loginRequestSent = false;
    },
    login() {
      if (loginRequestSent) {
        this.$store
          .dispatch("Authentication/signIn", this.formdata)
          .then((response) => {
            if (response) {
              this.$router.push({
                name: "KenoProV3",
              });
            }
          });
      }
    },
  },
};
</script>

<style scoped>
#bg {
  /* background-image: url("../../assets/Players/s64.jpg");
  background-image: url("../../assets/Players/s64.jpg"); */
  background-position: px;
  background-size: 380px;
  background-position-x: 450px;
  /* background-repeat: ; */
}
</style>
