var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-screen svg w-screen",attrs:{"id":"numFont"}},[(_vm.spinner)?_c('div',[_c('EstablishingConnxn')],1):_vm._e(),(!_vm.spinner)?_c('div',[(_vm.status !== 'video' && _vm.status !== 'lastGames')?_c('div',[(_vm.status !== 'Video')?_c('div',{staticClass:"grid grid-cols-5 h-screen"},[_c('div',{staticClass:"col-span-3"},[_c('div',[_c('div',{staticClass:"flex justify-between pl-12 pt-10"},[_c('div',{staticClass:"flex space-x-3 font-extrabold text-6xl 2xl:text-7xl 3xl:text-7xl 4xl:text-8xl"},[_vm._m(0),_c('div',{staticClass:"text-white m-0 pt-1 2xl:pt-2 4xl:pt-2",on:{"click":function($event){return _vm.logout()}}},[_vm._v(" "+_vm._s(_vm.paddedValue)+" ")])]),_c('div',{staticClass:"rounded-md text-center pr-8",style:({
                  opacity: _vm.num >= 1 && _vm.num <= 40 ? '100%' : '20%',
                })},[_vm._m(1)])])]),_c('div',{},[_c('div',[_c('div',{staticClass:"grid grid-cols-10 gap-0.5 3xl:gap-y-1 4xl:gap-y-0 4xl:gap-x-0.5 w-95 p-0.5"},_vm._l((80),function(i){return _c('div',{key:i,ref:"cards",refInFor:true,staticClass:"card font-family"},[_c('div',{class:{
                      'lucky rounded-2xl text-center w-16 h-16 bounce  text-5xl 2xl:text-6xl 2xl:w-20 2xl:h-20 3xl:text-6xl 3xl:w-22 3xl:h-22  4xl:text-7xl 4xl:w-28 4xl:h-28':
                        _vm.forStyle.includes(i) && i < 41,
                      'lucky2 rounded-2xl text-center w-16 h-16 bounce  text-5xl  2xl:text-6xl 2xl:w-20 2xl:h-20 3xl:text-6xl 3xl:w-22 3xl:h-22  4xl:text-7xl 4xl:w-28 4xl:h-28 ':
                        _vm.forStyle.includes(i) && i > 40,
                      'text-5xl  one text-N3 w-16 h-16 rounded-2xl s text-center  2xl:text-6xl 2xl:w-20 2xl:h-20 3xl:text-6xl 3xl:w-22 3xl:h-22  4xl:text-7xl 4xl:w-28 4xl:h-28':
                        !_vm.forStyle.includes(i),
                    },staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_vm._v(" "+_vm._s(i)+" ")])])}),0)])]),_c('Footer')],1),_c('div',{staticClass:"col-span-2 text-center w-full"},[_c('transition',{key:_vm.status,attrs:{"name":"fade","mode":"out-in"}},[(_vm.status == 'Timing')?_c('timer',{staticClass:"w-full h-screen bg-gradient-to-b from-D1 via-D2 to-D3"}):_vm._e(),_c('div',{staticClass:"background-image-container absolute background-image-transparent relative"},[_c('div',{staticClass:"flex justify-end relative text-YellowKeno text-3xl 2xl:text-4xl 3xl:text-5xl"},[(_vm.forStyle.length < 21)?_c('div',{staticClass:"flex text-white absolute top-24 right-0 transform -translate-x-1/2 -translate-y-2/3 z-10 text:"},[_c('p',{staticClass:"font-normal mt-4 2xl:mt-6 4xl:mt-8"},[_vm._v(" "+_vm._s(_vm.forStyle.length)+" ")]),_c('p',{staticClass:"font-normal mt-8 2xl:mt-10 4xl:mt-14"},[_vm._v("/")]),_c('p',{staticClass:"mt-12 3xl:mt-14 3xl:mt-16 4xl:mt-20"},[_vm._v("20")])]):_vm._e(),(_vm.forStyle.length > 20)?_c('div',{staticClass:"flex text-white absolute top-24 2xl:top-28 right-0 transform -translate-x-1/2 -translate-y-2/3 z-10"},[_c('p',{staticClass:"font-normal mt-4 2xl:mt-6 4xl:mt-8"},[_vm._v("20")]),_c('p',{staticClass:"font-normal text-3xl mt-8 2xl:mt-10 4xl:mt-14"},[_vm._v(" / ")]),_c('p',{staticClass:"mt-12 2xl:mt-14 3xl:mt-16 4xl:mt-20"},[_vm._v("20")])]):_vm._e()]),(_vm.num !== 0)?_c('div',{ref:"animatedElement",staticClass:"flex my-auto bottom-0 justify-center h-screen rounded-full text-center",class:['number', _vm.entranceAnimation],style:({
                  '--rotation': _vm.randomRotation,
                })},[_c('div',{staticClass:"flex shakingAnimation justify-center 2xl:w-110 2xl:h-110 w-100 h-100 3xl:py-2 4xl:w-120 4xl:h-120 m-auto p-auto text-black font-bold text-above9xl py-0"},[_c('img',{staticClass:"object-contain w-100 h-100 2xl:w-105 2xl:h-105 3xl:w-110 3xl:h-110 4xl:w-120 4xl:h-120",attrs:{"src":_vm.bg,"alt":_vm.num}})])]):_vm._e()])],1)],1)]):_vm._e()]):_vm._e(),(_vm.status == 'lastGames')?_c('div',{staticClass:"w-screen h-screen"},[_c('DrawnHistory',{attrs:{"textSize":"text-4xl lg:text-3xl 3xl:text-5xl 4xl:text-6xl  ","gameIdClass":"text-5xl lg:text-5xl lg:w-28 w-32  2xl:w-36   3xl:w-40   4xl:w-44 2xl:text-6xl 3xl:text-6xl 4xl:text-7xl","numbersClass":"lg:h-12 lg:w-12 xl:h-14 xl:w-14   rounded-full 2xl:w-16 2xl:h-16 3xl:w-18 3xl:h-18 4xl:w-24 4xl:h-24 4xl:py-2"}})],1):_vm._e(),(_vm.status == 'Video')?_c('div',[_c('video-player')],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-2 2xl:pt-3"},[_c('img',{staticClass:"h-12 2xl:h-14 3xl:h-16 4xl:h-22 object-contain",attrs:{"src":require("../../../public/New/Draw.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2 3xl:mt-3"},[_c('img',{staticClass:"object-contain h-12 2xl:h-14 3xl:h-16 4xl:h-22",attrs:{"src":require("../../../public/New/heads.svg"),"alt":""}})])
}]

export { render, staticRenderFns }