import axios from "axios";
import NProgress from "nprogress";
const API_URL = process.env.VUE_APP_API_BASE_URL;
import CryptoJS from "crypto-js";
const key = "loveIsAGameForFool";

function res() {
  // const encryptedToken = localStorage.getItem("encryptedAgentToken");
  // const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, key);
  // const tk_value = decryptedBytes.toString(CryptoJS.enc.Utf8);
  let tk_value = localStorage.getItem("cashierToken");
  let response = axios.create({
    baseURL: `${API_URL}/api/v1`,
    timeout: 200000,
    headers: {
      authorization: "Bearer " + tk_value,
    },
  });
  response.interceptors.request.use((config) => {
    NProgress.start();
    return config;
  });
  response.interceptors.response.use((config) => {
    NProgress.done();
    return config;
  });
  // console.log("response from axios: ", response);
  return response;
}

function resFile() {
  let tk_value = localStorage.getItem("encryptedAgentToken");
  let response = axios.create({
    baseURL: `${API_URL}/api/v1`,
    headers: {
      authorization: "Bearer " + tk_value,
      ContentType: "multipart/form-data",
    },
  });
  response.interceptors.request.use((config) => {
    NProgress.start();
    return config;
  });
  response.interceptors.response.use((config) => {
    NProgress.done();

    return config;
  });
  return response;
}

export default {
  getMe() {
    return res().get("/branchEmployees/profile/");
  },
  getLuckyNumbers(id) {
    return res().get(`/gameResults/cashier/search?q=${id}`);
  },
  todaysGamesHistory(id) {
    return res().get("/gameResults/todayGameResults");
  },
  updateMyProfile(payload) {
    return res().patch("/agents/updateProfile/", payload);
  },
  updateMyPassword(payload) {
    return res().patch("/agents/updateMyPassword/", payload);
  },
  sendForDevTeam(payload) {
    return res().post("/comments/", { comment: payload });
  },
  //CASHIER
  // Get Requests
  getAllTickets(perPage, page) {
    return res().get(`/tickets?sort=-createdAt&limit=${perPage}&page=${page}`);
  },
  getCountAllTickets() {
    return res().get("/tickets/countAgentTickets");
  },
  getAllTimeReport() {
    return res().get("/agents/statusData");
  },
  getTicketById(id) {
    return res().get(`/tickets/${id}`);
  },
  getEmployeeTransaction(id) {
    return res().get(`/reports/employee/${id}`);
  },
  getAllTicketsByDate(date) {
    return res().get(`/tickets/${date}`);
  },
  searchTicket(search) {
    // console.log("ov");
    return res().get(`/tickets/agent/search?q=${search}`);
  },
  // Post Requests
  // createTicket(payload) {
  //   console.log("ppppp :", payload);
  //   return res().post("/tickets/cashier", payload);
  // },

  // //Agents
  // // Get Request
  // getMe() {
  //   return res().get("/agents/profile/");
  // },
  // getAllBranch() {
  //   return res().get("/agents/");
  // },
  // getBranchById(id) {
  //   return res().get(`/agents/${id}`);
  // },
  // getSingleBranch(id) {
  //   return res().get(`/agents/${id}`);
  // },
  // searchBranch(search) {
  //   return res().get(`/agents/search/${search}`);
  // },
  // // Post Requests
  // createBranch(payload) {
  //   return res().post("/agents/signup", payload);
  // },
  // // Patch Request
  // activateBranchAccount(payload, id) {
  //   return res().patch(`/agents/activateAccount/${id}`, payload);
  // },
  // updateMyBranchProfile(payload) {
  //   return res().patch("/agents/updateProfile", payload);
  // },
  // updateMyPassword(payload) {
  //   return res().patch("/agents/updateMyPassword/", payload);
  // },
  // updateMyProfileByProxima(id) {
  //   return res().patch(`/agents/updateProfile/${id}`);
  // },
  //Branch Employee
  // Get Request
  todayReport() {
    return res().get("/tickets/agent/todayReport/");
  },
  getMeBranchEmployee() {
    return res().get("/branchEmployees/profile/");
  },
  getAllEmployee() {
    return res().get("/branchEmployees/");
  },
  getEmployeeById(id) {
    return res().get(`/branchEmployees/${id}`);
  },
  searchEmployee(search) {
    return res().get(`/branchEmployees/search/${search}`);
  },
  // Post Requests
  createEmployee(payload) {
    return res().post("/branchEmployees/signup", payload);
  },
  // Patch Request
  activateEmployeeAccount(id) {
    return res().patch(`/branchEmployees/changeActiveStatus/${id}`);
  },
  closeTodaysReport(id) {
    return res().patch(`/branchEmployees/markReport/${id}`);
  },
  editEmployeeByAgent({ id, payload }) {
    // console.log("payload: ", payload);
    return res().patch(`/branchEmployees/${id}`, payload);
  },
  updateMyProfileEmployee(payload) {
    return res().patch("/branchEmployees/updateProfile", payload);
  },
  updateMyPasswordEmployee(payload) {
    return res().patch("/branchEmployees/updateMyPassword/", payload);
  },
  forgotEmployeePassword(payload) {
    return res().patch("/branchEmployees/forgotPassword/", payload);
  },
  ResetEmployeePassword(payload) {
    return res().patch("/branchEmployees/resetPassword/", payload);
  },
};
