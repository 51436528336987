<template>
  <div class="">
    <label class="mb-5 text-gray-300 text-sm" v-if="label">{{ label }}</label>
    <input
      :value="value"
      @input="updateValue"
      v-bind="$attrs"
      v-on="listeners"
      :class="inputClass"
    />
  </div>
</template>

<script>
import { mixin } from "../../mixins/mixins";
export default {
  props: {
    inputClass: {},
  },
  mixins: [mixin],
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
  },
};
</script>

<style scoped>
.new {
  border-radius: 18px;
}
.grouplink {
  font: small;
  background-color: #e9e9e9;
  border-radius: 5px;
  padding-right: 0;
  width: 330px;
}
.groupname {
  font: small;
  background-color: #e9e9e9;
  border-radius: 5px;
  padding-right: 0;
  width: 530px;
}
/* .groupdescription {
  font: small;
  background-color: #e9e9e9;
  border-radius: 5px;
  padding-right: 0;
  width: 530px;
  height: 800px;
} */
</style>
