<template>
  <div
    id="numFont"
    class="w-screen h-screen bg-gradient-to-l from-D1 via-D2 to-D3 items-center flex justify-center b"
  >
    <div class="">
      <div class="flex">
        <img
          class="w-32 h-32 object-contain"
          src="../../public/logo.svg"
          alt=""
        />
        <div
          class="text-9xl bg-gradient-to-br from-redo via-7 to-YellowKeno text-transparent bg-clip-text"
        >
          KenoPro
        </div>
      </div>
      <div
        class="py-10 text-center bg-gradient-to-br from-YellowKeno via-7 to-YellowKeno text-transparent bg-clip-text"
      >
        <div class="text-4xl">Please check your Connection!</div>
        <div
          class="py-10 text-center bg-gradient-to-br from-YellowKeno via-7 to-YellowKeno text-transparent bg-clip-text"
        >
          <BaseButton
            autofocus
            @keypress.enter="tryAgain()"
            @click="tryAgain()"
            buttonClass="p-3 text-xl text-white rounded-md bg-gradient-to-r from-D3 via-7 to-7"
            >Try again</BaseButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
export default {
  components: { Spinner },
  methods: {
    tryAgain() {
      window.location.reload();
    },
  },
};
</script>

<style></style>
