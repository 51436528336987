import axios from "axios";
import NProgress from "nprogress";
const API_URL = process.env.VUE_APP_API_BASE_URL;
// import store from "@/store/store";

function res() {
  let response = axios.create({
    // withCredentials: false,
    // baseURL: `${API_URL}/api/v1`,
    baseURL: `${API_URL}/api/v1`,
    timeout: 20000,
  });
  response.interceptors.request.use((config) => {
    NProgress.start();
    return config;
  });
  response.interceptors.response.use((config) => {
    NProgress.done();
    return config;
  });
  return response;
}

export default {
  postLogin(formdata) {
    // console.log("post Login");
    return res().post(`/branchemployees/login`, formdata);
  },
  postSignup(fromdata) {
    return res().post("/users/signup/", fromdata);
  },
  ResetPassword(formdata) {
    return res().patch(`/agents/resetPassword`, formdata);
  },
  ResetPasswordByLink({ token, formdata }) {
    return res().patch(`/agents/resetPasswordByLink/${token}`, formdata);
  },
  forgetPassword(formdata) {
    return res().post(`/agents/forgotPassword`, formdata);
  },
};
