<template>
  <div class="w-full pt-10">
    <div class="flex justify-center space-x-3 font-extrabold">
      <div class="pt-2 2xl:pt-3">
        <!-- <p
          id="draw"
          class="bg-gradient-to-b from-time via-time to-black text-transparent text-6xl 2xl:text-7xl 3xl:text-8xl bg-clip-text"
        >
          DRAW
        </p> -->
        <img
          class="h-14 2xl:h-14 3xl:h-20 4xl:h-22 object-contain pt-1"
          src="../../../public/New/Draw.svg"
          alt=""
        />
      </div>
      <div
        id="gameId"
        @click="logout()"
        class="text-white text-6xl 2xl:text-7xl 3xl:text-8xl m-0"
      >
        {{ NonPaddedValue }}
      </div>
    </div>
    <BaseCountDown
      class="text-start 3xl:mb-8"
      timeClass="text-xlXl 2xl:text-minExtra 3xl:text-above9xl2 4xl:text-extra1"
      betClosedClass="text-8xl 2xl:text-8xl  3xl:text-9xl 4xl:text-minExtra"
    />
    <carousel
      carouselClass="text-6xl 2xl:text-7xl 3xl:text-7xl 4xl:text-8xl"
      width="w-52 2xl:w-56 3xl:w-64 4xl:w-80"
      widthPic="w-52 2xl:w-56 3xl:w-64  4xl:w-80"
      carouselNumber="w-12 h-12 mt-2  2xl:w-14 2xl:h-14 2xl:mt-3 3xl:h-16 3xl:h-16 3xl:mt-4 4xl:w-20 4xl:h-20"
      hits="text-5xl 2xl:text-6xl 4xl:text-7xl"
      items="text-4xl 2xl:text-5xl 4xl:text-6xl"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Carousel from "../Carousel.vue";
export default {
  components: {
    Carousel,
  },
  computed: {
    NonPaddedValue() {
      const subtractedGameId = this.gameId;
      const value = subtractedGameId.toString().padStart(3, "0");
      return value;
    },
    ...mapState("Game", ["gameId"]),
  },
};
</script>

<style></style>
