import moment from "moment";
import { EventBus } from "@/event/EventBus";
export const namespaced = true;
export const state = {
  formattedTime: "00:00",
  startingTime: 0,
  endingTime: 0,
  difference: 0,
  intervalId: null,
  timerDone: false,
  flash: false,
  // checkConnection: false,
};

export const mutations = {
  setStartAndEndTime(state, payload) {
    state.startingTime = payload.startingTime;
    state.endingTime = payload.endingTime;
    state.difference = payload.endingTime - payload.startingTime;
  },
  updateDifference(state, payload) {
    state.difference = payload;
  },
  clearCountdown(state) {
    clearInterval(state.intervalId);
    state.intervalId = null;
  },
  setFormattedTime(state) {
    state.formattedTime = moment.utc(state.difference).format("mm:ss");
  },
};

export const actions = {
  startCountdown({ state, dispatch, commit }, timeDifference) {
    commit("updateDifference", timeDifference);
    // console.log("time Diff: ", timeDifference);
    if (state.intervalId !== null) {
      return; // Countdown is already running
    }
    state.timerDone = false;
    commit("clearCountdown");

    commit("setFormattedTime"); // Initial display of countdown time

    // state.intervalId = setInterval(() => {
    //   if (state.difference <= 0) {
    //     state.flash = false;
    //     state.timerDone = true; // Set timerDone to true immediately upon reaching 00:00
    //     EventBus.$emit("checkConnection");
    //     clearInterval(state.intervalId);
    //     state.intervalId = null;
    //   } else {
    //     if (state.flash == false && state.difference <= 10000) {
    //       state.flash = true;
    //       state.difference -= 1000;
    //       // EventBus.$emit("PlayFlashSound");
    //       commit("setFormattedTime");
    //     } else {
    //       if (state.flash == true) {
    //         state.flash = false;
    //       }
    //       state.difference -= 1000;
    //       commit("setFormattedTime");
    //     }
    //   }
    // }, 1000);
    state.intervalId = setInterval(() => {
      if (state.difference <= 0) {
        state.flash = false;
        state.timerDone = true; // Set timerDone to true immediately upon reaching 00:00
        EventBus.$emit("checkConnection");
        clearInterval(state.intervalId);
        state.intervalId = null;
      } else {
        if (state.difference <= 10000 && state.flash == false) {
          state.flash = true;
          state.difference -= 1000;
          // EventBus.$emit("PlayFlashSound");
          commit("setFormattedTime");
        } else {
          // if (state.flash == true) {
          //   state.flash = false;
          // }
          state.difference -= 1000;
          commit("setFormattedTime");
        }
      }
    }, 1000);
  },
  stopCountdown({ commit }) {
    commit("clearCountdown");
  },
  checkConnection() {
    if (navigator.onLine) {
      return true;
    } else {
      EventBus.$emit("stopService");
      dispatch("Authentication/setTrue", { root: true });
    }
  },
};

export default {};
