<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <select :value="value" @input="updateValue" v-bind="$attrs">
      <option
        :class="selectClass"
        v-for="option in options"
        :key="option"
        :selected="option === value"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
import { mixin } from "../../mixins/mixins";
export default {
  mixins: [mixin],
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectClass: {
      type: String,
    },
  },
};
</script>

<style scoped>
.select {
  width: 250px;
}
</style>
