<template>
  <div>
    <!-- {{ animateNumbers }} -->
    <div
      class="grid grid-cols-10 gap-0.5 3xl:gap-y-1 4xl:gap-y-0 4xl:gap-x-0.5 w-95 p-0."
    >
      <div class="card font-family" v-for="i in 80" :key="i" ref="cards">
        <div
          id="numbers"
          :class="{
            'lucky rounded-2xl text-center w-16 h-18 bounce  text-xl5 2xl:text-xl6 2xl:w-20 2xl:h-20 3xl:text-xl7 3xl:w-20 3xl:h-24 object-fit  4xl:text-xl8 4xl:w-28 4xl:h-28':
              forStyle.includes(i) && i < 41,
            'lucky2 rounded-2xl text-center w-16 h-18 bounce  text-xl5  2xl:text-xl6 2xl:w-20 2xl:h-20 3xl:text-xl7 3xl:w-20 3xl:h-24 object-fit  4xl:text-xl8 4xl:w-28 4xl:h-28 ':
              forStyle.includes(i) && i > 40,
            'text-xl5  one text-N3 w-16 h-18 rounded-2xl s text-center  2xl:text-xl6 2xl:w-20 2xl:h-20 3xl:text-xl7 3xl:w-20 3xl:h-24 object-fit  4xl:text-xl8 4xl:w-28 4xl:h-28':
              !forStyle.includes(i),
          }"
          style="display: flex; align-items: center; justify-content: center"
        >
          <p class="mt-3">{{ i }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event/EventBus";
import { gsap } from "gsap";
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  created() {
    if (this.gameStatus === "newDrawnNumbers" && this.forStyle.length === 0) {
      // console.log("gameS: ", this.gameStatus);
      this.animateCards();
    }
    // EventBus.$on("animateCards", () => {
    //   console.log("in  event anima");
    //   this.animateCards();
    // });
  },
  beforeDestroy() {
    // EventBus.$off("animateCards");
  },
  watch: {
    // animateNumbers(newStatus) {
    //   if (newStatus == true) {
    //     this.$nextTick(() => {
    //       console.log("watch:", this.$refs.cards);
    //       this.animateCards();
    //     });
    //   }
    // },
  },
  computed: {
    ...mapState("Game", ["animateNumbers"]),
    ...mapState("Game", ["gameStatus"]),
    ...mapState("Game", ["forStyle"]),
    ...mapState("Game", ["num"]),
  },
  methods: {
    // animateCards() {
    //   console.log("hello");
    //   gsap.from(this.$refs.cards, {
    //     duration: 1,
    //     // delay: 0.5,
    //     scale: 0.1,
    //     y: 800,
    //     x: -800,
    //     ease: "power1",
    //     stagger: {
    //       grid: [7, 17],
    //       from: "center",
    //       amount: 1,
    //     },
    //   });
    // },
    // animateCards() {
    //   this.$nextTick(() => {
    //     gsap.from(this.$refs.cards, {
    //       // Target all card elements for animation
    //       duration: 1,
    //       scale: 0.1,
    //       y: 800,
    //       x: -800,
    //       ease: "power1",
    //       stagger: {
    //         grid: [7, 17],
    //         from: "center",
    //         amount: 1,
    //         each: 0.1, // Add a small stagger between each element
    //       },
    //     });
    //   });
    // },
    // animateCards() {
    //   this.$nextTick(() => {
    //     gsap.from(this.$refs.cards, {
    //       // Target all card elements for animation
    //       duration: 1,
    //       scale: 0.1,
    //       y: 800,
    //       x: -800,
    //       ease: "power1",
    //       stagger: {
    //         grid: [12, 20], // Adjust the grid dimensions to accommodate the stagger sequence
    //         from: "center",
    //         amount: 1,
    //         each: 0.1, // Add a small stagger between each element
    //       },
    //     });
    //   });
    // },
    animateCards() {
      this.$nextTick(() => {
        gsap.from(this.$refs.cards, {
          // Target all card elements for animation
          duration: 1,
          scale: 0.1,
          y: 800,
          x: -800,
          ease: "power1",
          stagger: {
            grid: [10, 8], // Update grid to match the number of cards in each row
            from: "start", // Start the animation from the first card
            axis: "x", // Stagger along the X-axis
            amount: 1, // Stagger amount between each element
            each: 0.1, // Add a small stagger between each element
            from: "center", // Start the stagger from the center of the grid
            each: 0.1, // Add a small stagger between each element
            from: "edges", // Alternate the stagger start point between the start and end of the grid
            amount: {
              // Define the custom staggering sequence
              grid: 80, // Total number of cards
              values: (i, target, targets) => {
                const sequence = [
                  10,
                  9,
                  20, // Add the custom sequence of numbers here
                  8,
                  19,
                  30,
                  7,
                  18,
                  29,
                  40,
                  6,
                  17,
                  28,
                  39,
                  50,
                  5,
                  16,
                  27,
                  38,
                  49,
                  60,
                  4,
                  15,
                  26,
                  37,
                  48,
                  59,
                  70,
                  3,
                  14,
                  25,
                  6,
                  47,
                  58,
                  69,
                  80,
                  2,
                  13,
                  24,
                  35,
                  46,
                  57,
                  68,
                  79,
                  1,
                  12,
                  23,
                  34,
                  45,
                  56,
                  67,
                  78,
                  11,
                  22,
                  33,
                  44,
                  55,
                  66,
                  77,
                  21,
                  32,
                  43,
                  54,
                  65,
                  76,
                  31,
                  42,
                  53,
                  64,
                  75,
                  41,
                  52,
                  63,
                  74,
                  51,
                  62,
                  73,
                  61,
                  72,
                  // ... continue the sequence pattern for the remaining numbers
                  // Ensure that the sequence includes all the specified numbers in the correct order
                  // The last number in the sequence should be 72
                  // Remember to adjust the grid dimensions if the number of cards per row changes
                ];
                return sequence.indexOf(i + 1) !== -1 ? 0 : 1; // Add a small stagger delay for the specified numbers
              },
            },
          },
        });
      });
    },

    // animateCards() {
    //   this.$nextTick(() => {
    //     this.$refs.cards.forEach((card) => {
    //       gsap.from(card, {
    //         // Target each individual card element for animation
    //         duration: 1,
    //         scale: 0.1,
    //         y: 800,
    //         x: -800,
    //         ease: "power1",
    //         stagger: {
    //           grid: [7, 17],
    //           from: "center",
    //           amount: 1,
    //         },
    //       });
    //     });
    //   });
    // },
  },
};
</script>

<style>
.card {
  padding: 0;
}

.card > div {
  width: 100%;
  padding: 0;
  margin: 0;
}
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}
@keyframes bounce-animation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.3);
  }
}

.bounce {
  animation: bounce-animation 0.5s ease-in-out 1;
  z-index: 1000;
}
.lucky2 {
  /* background-image: url("../../../public/New/4080box.svg"); */
  background-image: url("../../../public/New/numBgSelcetedTail.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.lucky {
  /* background-image: url("../../../public/New/140box.svg"); */
  background-image: url("../../../public/New/numBgSelectedHead.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.one {
  background-image: url("../../../public/New/numBgMD.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
#numbers {
  font-family: "numbers";
}
@font-face {
  font-family: "numbers";
  src: url("../../../public/fonts/eurostile_next_lt_pro_semibold.ttf")
    format("woff2");
}
</style>
