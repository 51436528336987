import Vue from "vue";
import VueRouter from "vue-router";
import AxiosServices from "@/services/AxiosServices";
import ErrorHandler from "@/services/ErrorHandler";

// Error
import NotFound from "../Error/NotFound.vue";
import NetworkIssue from "../Error/NetworkIssue.vue";
// import KenoProV6 from "../views/User/KenoProV6.vue";
import Login from "../views/Authentication/Login.vue";
// import KenoProV7 from "../views/User/KenoProV7.vue";
import Parent from "../views/User/Parent.vue";
const preventRoute = {
  beforeEnter: (to, from, next) => {
    if (localStorage.getItem("cashierToken")) {
      AxiosServices.getMe()
        .then((response) => {
          if (response) {
            next();
          } else {
            ErrorHandler(error);
          }
        })
        .catch((error) => {
          ErrorHandler(error);
        });
    } else {
      localStorage.clear();
      next("/");
    }
  },
};
const requireAuth = (to, from, next) => {
  const token = localStorage.getItem("cashierToken");
  if (token) {
    next("/KenoPro");
  } else {
    if (to.path === "/") {
      // check if current route is the same as destination route
      next();
    } else {
      next("/");
    }
  }
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: {
      title: "NotFound",
    },
  },
  {
    beforeEnter: requireAuth,
    path: "/",
    name: "log-in",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/network-issue",
    name: "NetworkIssue",
    component: NetworkIssue,
    meta: {
      title: "Network issue",
    },
  },
  {
    path: "*",
    redirect: { name: "404" },
  },

  {
    path: "/kenoPro",
    name: "kenoPro",
    component: Parent,
    meta: {
      title: "KenoPro",
    },
    ...preventRoute,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: () => {
    return {
      x: 0,
      y: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  document.title = ` ${to.meta.title}`;
  next();
});

export default router;
