import Vue from "vue";

export const namespaced = true;

export const state = {
  audioUrls: [],
};
export const mutations = {
  setAudioUrls(state, urls) {
    state.audioUrls = urls;
  },
};
export const actions = {
  async preloadAudio({ commit, dispatch }) {
    const audioUrls = [];

    for (let i = 1; i <= 80; i++) {
      const audioUrl = require(`../../../public/sounds/${i}a.mp3`);

      try {
        const audioBlobUrl = await dispatch("loadAudioFromNetwork", audioUrl);
        audioUrls.push(audioBlobUrl);
        // console.log(`Audio ${i} loaded successfully!`);
      } catch (error) {
        console.error(`Error loading audio ${audioUrl}:`, error);
        audioUrls.push(null);
      }
    }

    commit("setAudioUrls", audioUrls);
    return audioUrls;
  },

  async loadAudioFromNetwork({ commit }, audioUrl) {
    try {
      const response = await fetch(audioUrl);

      if (response.ok) {
        const audioBlob = await response.blob();
        const blobUrl = URL.createObjectURL(audioBlob);
        return blobUrl;
      } else {
        throw new Error(`Failed to fetch audio ${audioUrl}`);
      }
    } catch (error) {
      throw error;
    }
  },
};

export const getters = {};

export default {};
