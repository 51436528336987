/* eslint-disable */
import Vue from "vue";
import CryptoJS from "crypto-js";
const key = "loveIsAGameForFool";
import AxiosService from "../../services/AxiosServices";
import AuthenticationServices from "@/services/AuthenticationServices";
import ErrorHandler from "../../services/ErrorHandler";
import router from "@/router";
import { EventBus } from "@/event/EventBus";
export const namespaced = true;
export const state = {
  userInfo: [],
  userToken: null,
  noConnection: false,
  userRole: "",
  nightMode: "",
  me: [],
  //   this.socket = io("https://server.proximastech.com");
};

export const mutations = {
  //Signin
  USER_TOKEN(state, token) {
    state.userToken = token;
  },
  USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
};
export const actions = {
  logOut() {
    const routeToHome = () => {
      localStorage.removeItem("name");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userRole");
      this.$router.push({
        name: "log-in",
      });
    };
    window.setTimeout(routeToHome, 1000);
  },
  //signup

  getNumbers({ state, dispatch }, id) {
    return AxiosService.getLuckyNumbers(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },

  setTrue({ state, dispatch }) {
    // console.log("error");
    state.noConnection = true;
    const notification = {
      type: "error",
      message: "It seems there is network issue please check your connection!",
    };
    dispatch("notification/add", notification, { root: true });
  },
  setFalse({ state }) {
    state.noConnection = false;
  },
  todaysGamesHistory({ state, dispatch }) {
    return AxiosService.todaysGamesHistory()
      .then((res) => {
        return res;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getMe({ state }) {
    return AxiosService.getMe()
      .then((res) => {
        state.me = res.data.user;
        // console.log("user: ", res.data.user);
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  signIn({ state, commit, dispatch }, payload) {
    // console.log(payload)
    return AuthenticationServices.postLogin(payload)
      .then((res) => {
        // console.log("res status sts: ", res.data.status);
        // console.log("res  sts: ", res);
        if (res.data.status === "success") {
          let user = {
            id: res.data.user._id,
            agentName: res.data.user.agentName,
            employeeName: res.data.user.employeeName,
            email: res.data.user.email,
            role: res.data.user.role,
            pic: res.data.user.photos,
            phoneNumber: res.data.user.phoneNumber,
          };
          // const encryptedToken = CryptoJS.AES.encrypt(
          //   res.data.token,
          //   key
          // ).toString();
          localStorage.setItem("cashierToken", res.data.token);
          // localStorage.setItem("encryptedAgentToken", res.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          commit("USER_INFO", res.data.user);
          commit("USER_TOKEN", res.data.token);
          state.nightMode = res.data.user.nightMode;
          // console.log("night Mode", state.nightMode);
          const notification = {
            type: "success",
            message: "You have signed in successfully",
          };
          dispatch("notification/add", notification, { root: true });
          return true;
        }
      })
      .catch((error) => {
        if (error.code == "ERR_BAD_REQUEST") {
          if (error.response.data.error.statusCode == 401) {
            localStorage.clear();
            // router.push("/");
            const notification = {
              type: "error",
              message: error.response.data.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          } else {
            const notification = {
              type: "error",
              message: error.response.data.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        } else {
          ErrorHandler(error);
          return false;
        }
      });
  },
  logOut({ dispatch }) {
    const notification = {
      type: "logout",
      message: "You have signed off successfully",
    };
    dispatch("notification/add", notification, { root: true });
  },
  eventCheck() {
    EventBus.$emit("testing-event");
  },
};
export const getters = {};

export default {};
