<template>
  <div id="app" class="h-screen common timer">
    <notification-container style="z-index: 100" class="z-50" />
    <router-view />
  </div>
</template>
<script>
import NotificationContainer from "./components/Notifications/NotificationContainer.vue";
export default {
  components: { NotificationContainer },
  data() {
    return {
      assets: false,
      failed: false,
      complete: false,
    };
  },
};
</script>

<style></style>
