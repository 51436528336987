<template>
  <div id="numFont" class="h-screen svg w-screen">
    <div v-if="spinner"><EstablishingConnxn /></div>
    <div v-if="!spinner">
      <div v-if="status !== 'video' && status !== 'lastGames'">
        <div v-if="status !== 'Video'" class="grid grid-cols-5 h-screen">
          <div class="col-span-3">
            <!-- <Header /> -->
            <div>
              <div class="flex justify-between pl-12 pt-10">
                <div
                  class="flex space-x-3 font-extrabold text-6xl 2xl:text-7xl 3xl:text-7xl 4xl:text-8xl"
                >
                  <div class="pt-2 2xl:pt-3">
                    <img
                      class="h-12 2xl:h-14 3xl:h-16 4xl:h-22 object-contain"
                      src="../../../public/New/Draw.svg"
                      alt=""
                    />
                  </div>
                  <div
                    @click="logout()"
                    class="text-white m-0 pt-1 2xl:pt-2 4xl:pt-2"
                  >
                    {{ paddedValue }}
                  </div>
                </div>
                <div
                  :style="{
                    opacity: num >= 1 && num <= 40 ? '100%' : '20%',
                  }"
                  class="rounded-md text-center pr-8"
                >
                  <div class="mt-2 3xl:mt-3">
                    <img
                      class="object-contain h-12 2xl:h-14 3xl:h-16 4xl:h-22"
                      src="../../../public/New/heads.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <Numbers class="w-full pl-12" /> -->
            <div class="">
              <div>
                <!-- {{ animateNumbers }} -->
                <div
                  class="grid grid-cols-10 gap-0.5 3xl:gap-y-1 4xl:gap-y-0 4xl:gap-x-0.5 w-95 p-0.5"
                >
                  <div
                    class="card font-family"
                    v-for="i in 80"
                    :key="i"
                    ref="cards"
                  >
                    <div
                      :class="{
                        'lucky rounded-2xl text-center w-16 h-16 bounce  text-5xl 2xl:text-6xl 2xl:w-20 2xl:h-20 3xl:text-6xl 3xl:w-22 3xl:h-22  4xl:text-7xl 4xl:w-28 4xl:h-28':
                          forStyle.includes(i) && i < 41,
                        'lucky2 rounded-2xl text-center w-16 h-16 bounce  text-5xl  2xl:text-6xl 2xl:w-20 2xl:h-20 3xl:text-6xl 3xl:w-22 3xl:h-22  4xl:text-7xl 4xl:w-28 4xl:h-28 ':
                          forStyle.includes(i) && i > 40,
                        'text-5xl  one text-N3 w-16 h-16 rounded-2xl s text-center  2xl:text-6xl 2xl:w-20 2xl:h-20 3xl:text-6xl 3xl:w-22 3xl:h-22  4xl:text-7xl 4xl:w-28 4xl:h-28':
                          !forStyle.includes(i),
                      }"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      {{ i }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>

          <div class="col-span-2 text-center w-full">
            <transition name="fade" mode="out-in" :key="status">
              <timer
                v-if="status == 'Timing'"
                class="w-full h-screen bg-gradient-to-b from-D1 via-D2 to-D3"
              />
              <!-- <Drawing v-if="status == 'Drawing'" class="w-full" /> -->
              <div
                class="background-image-container absolute background-image-transparent relative"
              >
                <div
                  class="flex justify-end relative text-YellowKeno text-3xl 2xl:text-4xl 3xl:text-5xl"
                >
                  <div
                    v-if="forStyle.length < 21"
                    class="flex text-white absolute top-24 right-0 transform -translate-x-1/2 -translate-y-2/3 z-10 text:"
                  >
                    <p class="font-normal mt-4 2xl:mt-6 4xl:mt-8">
                      {{ forStyle.length }}
                    </p>
                    <p class="font-normal mt-8 2xl:mt-10 4xl:mt-14">/</p>
                    <p class="mt-12 3xl:mt-14 3xl:mt-16 4xl:mt-20">20</p>
                  </div>
                  <div
                    v-if="forStyle.length > 20"
                    class="flex text-white absolute top-24 2xl:top-28 right-0 transform -translate-x-1/2 -translate-y-2/3 z-10"
                  >
                    <p class="font-normal mt-4 2xl:mt-6 4xl:mt-8">20</p>
                    <p class="font-normal text-3xl mt-8 2xl:mt-10 4xl:mt-14">
                      /
                    </p>
                    <p class="mt-12 2xl:mt-14 3xl:mt-16 4xl:mt-20">20</p>
                  </div>
                </div>

                <div
                  ref="animatedElement"
                  v-if="num !== 0"
                  class="flex my-auto bottom-0 justify-center h-screen rounded-full text-center"
                  :style="{
                    '--rotation': randomRotation,
                  }"
                  :class="['number', entranceAnimation]"
                >
                  <div
                    class="flex shakingAnimation justify-center 2xl:w-110 2xl:h-110 w-100 h-100 3xl:py-2 4xl:w-120 4xl:h-120 m-auto p-auto text-black font-bold text-above9xl py-0"
                  >
                    <img
                      class="object-contain w-100 h-100 2xl:w-105 2xl:h-105 3xl:w-110 3xl:h-110 4xl:w-120 4xl:h-120"
                      :src="bg"
                      :alt="num"
                    />
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <!-- last game -->
      <div v-if="status == 'lastGames'" class="w-screen h-screen">
        <DrawnHistory
          textSize="text-4xl lg:text-3xl 3xl:text-5xl 4xl:text-6xl  "
          gameIdClass="text-5xl lg:text-5xl lg:w-28 w-32  2xl:w-36   3xl:w-40   4xl:w-44 2xl:text-6xl 3xl:text-6xl 4xl:text-7xl"
          numbersClass="lg:h-12 lg:w-12 xl:h-14 xl:w-14   rounded-full 2xl:w-16 2xl:h-16 3xl:w-18 3xl:h-18 4xl:w-24 4xl:h-24 4xl:py-2"
        />
      </div>
      <!-- video -->
      <div v-if="status == 'Video'">
        <video-player />
      </div>
      <!-- </transition> -->
    </div>
    <!-- <transition name="fade" mode="out-in" :key="status"> -->
  </div>
</template>

<script>
import VideoPlayer from "../../components/VideoPlayer.vue";
import io from "socket.io-client";
import { EventBus } from "@/event/EventBus";
import { mapState, mapMutations } from "vuex";
import Numbers from "@/components/Main/Numbers.vue";
import Header from "@/components/Main/Header.vue";
import Footer from "@/components/Main/Footer.vue";
import EstablishingConnxn from "@/components/Main/EstablishingConnxn.vue";
import Timer from "@/components/Main/Timer.vue";
import DrawnHistory from "../../components/DrawnHistory.vue";
import Drawing from "../../components/Main/Drawing.vue";
export default {
  components: {
    Numbers,
    Header,
    Footer,
    Timer,
    Drawing,
    VideoPlayer,
    DrawnHistory,
    EstablishingConnxn,
  },
  data() {
    return {
      spinner: true,
      socket: {},
      audioElement: null,
      shuffle: null,
      numSound: null,
      animate: null,
      inMiddleGame: null,
      numberAudio: new Audio(),
      clickAudio: new Audio(),
      soundFiles: [],
      randomRotation: "",
      rotations: ["-10deg", "-5deg", "0deg", "5deg", "10deg"],
    };
  },
  created() {
    this.socket = io("https://server.proximastech.com");
    EventBus.$on("runTimerFunctions", () => {
      this.runTimerFunction();
    });
    EventBus.$on("runDrawingFunctions", () => {
      this.runDrawingFunctions();
    });
  },
  beforeDestroy() {
    EventBus.$off("runTimerFunctions");
    EventBus.$off("runDrawingFunctions");
  },
  mounted() {
    this.socket.on("message", (message) => {
      clearInterval(this.animate);
      // if (this.numSound) {
      //   this.numSound.remove();
      //   this.numSound = null;
      // }
      // if (this.shuffle) {
      //   this.shuffle.remove();
      //   this.shuffle = null;
      // }
      // if (
      //   this.gameStatus == "newGame" ||
      //   this.gameStatus == "existingNewGame"
      // ) {
      //   this.PlayTimerMusic();
      //   this.fillPreviousGame();
      // } else if (this.gameStatus == "newDrawnNumbers") {
      //   this.stopTimerMusic();
      //   this.getNumbersInStart();
      // } else if (this.gameStatus == "existingDrawnNumbers") {
      //   this.getNumbersInMiddle();
      //   clearInterval(this.inMiddleGame);
      // }
      this.spinner = false;
      this.$store.dispatch("GameRule/settingUp", message);
      // this.enterFullscreen();
    });
  },
  watch: {
    // num(newValue) {
    //   this.playSound(newValue);
    // },
  },
  computed: {
    ...mapState("GameRule", ["num"]),
    ...mapState("GameRule", ["status"]),
    ...mapState("GameRule", ["index"]),
    ...mapState("Game", ["gameId"]),
    ...mapState("GameRule", ["luckyNumbers"]),
    ...mapState("GameRule", ["status"]),
    ...mapState("GameRule", ["gameStatus"]),

    paddedValue() {
      const subtractedGameId = this.gameId - 1;
      const value = subtractedGameId.toString().padStart(3, "0");
      return value;
    },
    bg() {
      return this.imageUrls[this.num - 1];
    },
    ...mapState("LoadImages", ["imageUrls"]),
    ...mapState("GameRule", ["forStyle"]),
    ...mapState("GameRule", ["num"]),
    ...mapState("GameRule", ["entranceAnimation"]),
    ...mapState("Game", ["animateNumbers"]),

    // ...mapState("Game", ["audioElement"]),
    // ...mapState("Game", ["shuffle"]),
  },
  methods: {
    preloadAudioFiles() {
      for (let i = 1; i <= 80; i++) {
        this.soundFiles.push(`/sounds/${i}a.opus`);
      }

      this.soundFiles.forEach((file) => {
        const audio = new Audio();
        audio.src = file;
        audio.preload = "auto";
        audio.muted = true;
        audio.play();
        audio.onloadeddata = () => {
          // console.log(`${file} has been preloaded`);
        };
      });
    },
    ...mapMutations("GameRule", ["updateStatus"]),
    ...mapMutations("GameRule", ["updateNum"]),
    ...mapMutations("GameRule", ["updateNum"]),
    ...mapMutations("GameRule", ["pushActiveIndexNum"]),
    ...mapMutations("GameRule", ["updateEntrance"]),
    ...mapMutations("GameRule", ["updateStatus"]),
    ...mapMutations("GameRule", ["updateIndex"]),
    ...mapMutations("GameRule", ["clearState"]),
    enterFullscreen() {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },
    runTimerFunction() {
      // if (this.numSound) {
      //   this.numSound.remove();
      //   this.numSound = null;
      // }
      this.PlayTimerMusic();
      this.stopShufflingSound();
      // console.log("running timer functions");
    },
    runDrawingFunctions() {
      this.clearState();
      this.playShufflingSound();
      this.stopTimerMusic();
      this.$store
        .dispatch("GameRule/getLuckyNumbers")
        .then((res) => {
          if (res) {
            if (this.gameStatus == "newDrawnNumbers") {
              this.startAnimation();
            } else if (this.gameStatus == "existingDrawnNumbers") {
              this.inMiddleGameAnimation();
            }
          } else {
            // console.log("no response");
          }
        })
        .catch((error) => {
          // console.log("some thing went wrong: ", error);
        });

      // console.log("running drawing functions");
    },
    checkConnection() {
      this.isCountingDown = false;
      if (navigator.onLine) {
        // console.log("no");
        return true;
      } else {
        // console.log("no");
        this.stopTimerMusic();
        this.$store.dispatch("Authentication/setTrue");
      }
    },
    startAnimation() {
      // console.log("in start");
      this.updateIndex(0);
      clearInterval(this.animate);
      // this.animateCards();
      setTimeout(() => {
        // console.log("here");
        this.updateStatus("Drawing");
        // console.log("here :");
        // this.animateNumbers = true;
        setTimeout(() => {
          this.animate = setInterval(() => {
            if (this.index < this.luckyNumbers.length) {
              const num = this.luckyNumbers[this.index];
              this.updateNum(num);
              // EventBus.$emit("randomRotationValue");
              this.randomRotationValue();
              this.updateEntrance("entrance");
              this.playClickSound();
              this.playSound();
              this.pushActiveIndexNum(num);
              setTimeout(() => {
                this.updateEntrance("exit");
              }, 2200);
            } else {
              setTimeout(() => {
                this.updateStatus("lastGames");
                this.PlayTimerMusic();
                this.stopShufflingSound();
              }, 2000);
              setTimeout(() => {
                this.checkConnection();
              }, 17000);
              // this.animateNumbers = false;
              clearInterval(this.animate);
            }
            this.updateIndex();
          }, 2500);
        }, 1000);
        // this.animateCards();
      }, 3500);
    },
    // constantly playing audios
    playSound() {
      const toPlay = Number(this.num);
      this.numberAudio.src = "/sounds/" + toPlay + "a" + ".opus";
      this.numberAudio.autoplay = true;
      document.body.appendChild(this.numberAudio);
    },
    // playSound() {
    //   const toPlay = Number(this.num);
    //   const audio = new Audio(this.soundFiles[toPlay - 1]); // Adjust index to match toPlay
    //   audio.play();
    // },

    playClickSound() {
      this.clickAudio.src = "/sounds/additional/BallSound.opus";
      this.clickAudio.play();
    },
    // playSound() {
    //   const Audio = document.createElement("video");
    //   let toPlay = Number(this.num);
    //   Audio.src = "/sounds/" + toPlay + "a" + ".opus";
    //   Audio.autoplay = true;
    // },
    // playClickSound() {
    //   const Audio1 = document.createElement("video");
    //   Audio1.src = "/sounds/additional/BallSound.opus";
    //   Audio1.autoplay = true;
    // },
    // shuffle Player
    playShufflingSound() {
      this.shuffle = null;
      this.shuffle = document.createElement("video");
      this.shuffle.src = "/sounds/additional/MachineSound.opus";
      this.shuffle.volume = 0.15; // Set volume to 50%
      this.shuffle.autoplay = true;
      this.shuffle.loop = true;
    },
    randomRotationValue() {
      this.randomRotation =
        this.rotations[Math.floor(Math.random() * this.rotations.length)];
    },
    // Timer player
    PlayTimerMusic() {
      this.stopShufflingSound();
      if (!this.audioElement) {
        const audioSources = [
          "/sounds/additional/Track1.opus",
          "/sounds/additional/Track2.opus",
          "/sounds/additional/Track3.opus",
        ];
        const randomIndex = Math.floor(Math.random() * audioSources.length);
        const randomSource = audioSources[randomIndex];
        this.audioElement = document.createElement("audio");
        this.audioElement.src = randomSource;
        this.audioElement.autoplay = true;
        this.audioElement.loop = true;
        document.body.appendChild(this.audioElement);
      }
    },

    // stop sounds
    stopShufflingSound() {
      if (this.shuffle) {
        this.shuffle.pause();
        this.shuffle.remove();
        // this.shuffle.currentTime = 0;
        // this.shuffle.src = "";
        this.shuffle = null;
      }
    },

    stopTimerMusic() {
      if (this.audioElement) {
        this.audioElement.pause();
        this.audioElement = null; // reset the reference to clear it from memory
      }
    },
    stopService(data) {
      this.stopTimerMusic();
      this.stopShufflingSound();
      if (data == "logout") {
        this.socket.disconnect();
        this.socket = null;
      }
    },
  },
};
</script>

<style></style>
