/* eslint-disable */
import Vue from "vue";
export const namespaced = true;
export const state = {
  videoURL: "",
  progress: 0,
};

export const mutations = {
  setVideoURL(state, url) {
    state.videoURL = url;
  },
  setProgress(state, value) {
    state.progress = value;
  },
};

export const actions = {
  async preloadVideo({ commit, dispatch }) {
    const videoUrl = require("../../../public/M.mp4");
    const cacheName = "video-cache";

    if ("caches" in window) {
      const cache = await window.caches.open(cacheName);
      const response = await cache.match(videoUrl);

      if (response) {
        const blobUrl = URL.createObjectURL(await response.blob()); // create Object URL from blob

        commit("setVideoURL", blobUrl);
        return blobUrl;
      } else {
        return dispatch("loadVideoFromNetwork", { videoUrl, cache });
      }
    } else {
      return dispatch("loadVideoFromNetwork", { videoUrl });
    }
  },

  async loadVideoFromNetwork({ commit }, { videoUrl, cache }) {
    const response = await fetch(videoUrl);
    const responseToCache = response.clone();

    if (response.ok) {
      const videoBlob = await response.blob();
      const blobUrl = URL.createObjectURL(videoBlob);

      commit("setVideoURL", blobUrl);

      if (cache) {
        cache.put(videoUrl, responseToCache);
      }

      return blobUrl;
    } else {
      throw new Error("Network response was not ok.");
    }
  },
};

export const getters = {};

export default {};
