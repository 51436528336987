<template>
  <div
    id="numbers"
    :class="textSize"
    class="p-4 3xl:p-0 w-screen h-screen text-center bg-gradient-to-l from-D1 via-D2 to-D3 items-center flex justify-center b"
  >
    <div class="">
      <div v-for="(i, index) in data" :key="index" class="flex space-x-8">
        <div :class="gameIdClass" class="py-2 text-white">
          {{ i.gameId }}
        </div>
        <div class="grid grid-cols-20 gap-0 mx-1">
          <template v-for="(n, index) in i.numbers_That_Won">
            <div>
              <div
                :style="{
                  backgroundColor: n > 0 && n < 40 ? '#EBD600' : '#E77907',
                }"
                :class="numbersClass"
                class="rounded-full p-2 4xl:mt-1 3xl:mt-2 items-center text-center"
              >
                {{ n }}
              </div>
              <p class="my-1 border-yellow-400 border"></p>
              <!-- Add a vertical line after every 10 numbers -->
              <!-- <div
                v-if="(index + 1) % 10 === 0"
                class="my-2 h-16 w-1 bg-black"
              ></div> -->
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
    };
  },
  props: {
    gameIdClass: {
      required: true,
      type: String,
    },
    numbersClass: {
      required: true,
      type: String,
    },
    textSize: {
      required: true,
      type: String,
    },
  },
  created() {
    this.fetchLastGames();
  },
  computed: {
    // computedArray() {
    //   return this.data.map((obj) => {
    //     const sortedNumbers = obj.numbers_That_Won
    //       .slice()
    //       .sort((a, b) => a - b);
    //     return { gameId: obj.gameId, numbers_That_Won: sortedNumbers };
    //   });
    // },
  },
  methods: {
    fetchLastGames() {
      this.$store.dispatch("Authentication/todaysGamesHistory").then((res) => {
        if (res) {
          this.data = res.data.doc;
          this.data.forEach((element) => {
            element.numbers_That_Won.sort((a, b) => a - b);
          });
          this.data.reverse();
          // console.log("data: ", this.data);
        }
      });

      // this.$store.dispatch("Authentication/todaysGamesHistory").then((res) => {
      //   if (res) {
      //     this.data = res.data.doc.sort((a, b) => {
      //       if (a.propertyToSort > b.propertyToSort) {
      //         return -1; // Move 'a' one position up
      //       } else if (a.propertyToSort < b.propertyToSort) {
      //         return 1; // Move 'a' one position down
      //       } else {
      //         return 0; // Keep the order unchanged
      //       }
      //     });
      //     // this.data = res.data.doc;
      //     console.log("data: ", this.data);
      //   }
      // });
    },
  },
};
</script>

<style></style>
