<template>
  <div class="video-container w-full">
    <!-- {{ videoURL }} -->
    <!-- <video autoplay ref="videoPlayer"></video> -->
    <!-- {{ videoURL }} -->
    <video autoplay :src="videoURL" ref="videoPlayer">{{ videoURL }}</video>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  mounted() {
    // this.preloadVideo();
  },
  computed: {
    ...mapState({
      videoURL: (state) => state.LoadAssets.videoURL,
    }),
  },
  mounted() {
    // console.log("mounted: ", videoURL);
  },
  methods: {
    hey() {
      // console.log("djhfjh");
    },
    async preloadVideo() {
      const videoUrl = require("../../public/M.mp4");
      const videoElement = this.$refs.videoPlayer;
      const cacheName = "video-cache";

      if ("caches" in window) {
        try {
          const cache = await window.caches.open(cacheName);
          const response = await cache.match(videoUrl);

          if (response) {
            const blobUrl = URL.createObjectURL(response);
            videoElement.src = blobUrl;
            // console.log("video is found in cache!");
          } else {
            this.loadVideoFromNetwork(videoUrl, cache);
          }
        } catch (error) {
          // console.error("Error opening cache:", error);
        }
      } else {
        this.loadVideoFromNetwork(videoUrl);
        return false;
      }
    },

    async loadVideoFromNetwork(videoUrl, cache) {
      try {
        const response = await fetch(videoUrl);

        if (response.ok) {
          const videoBlob = await response.blob();
          const blobUrl = URL.createObjectURL(videoBlob);
          this.$refs.videoPlayer.src = blobUrl;

          if (cache) {
            if (!response.bodyUsed) {
              // Response body is not used, so clone the response
              const responseClone = response.clone();
              await cache.put(videoUrl, responseClone);
            } else {
              console.warn(
                "Response body is already used, cannot clone response"
              );
            }
          }
        }
      } catch (error) {
        console.error("Error fetching video:", error);
      }
    },

    handleVideoLoaded() {
      // console.log("Video fetched from cache!");
    },
  },
};
</script>

<style>
.video-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
