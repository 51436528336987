import Vue from "vue";
import Vuex from "vuex";
import * as Authentication from "./module/Authentication";
import * as notification from "./module/notification";
import * as LoadAssets from "./module/LoadAssets";
import * as LoadImages from "./module/LoadImages";
import * as LoadAudios from "./module/LoadAudios";
import * as GameRule from "./module/GameRule";
import * as BaseCountDown from "./module/BaseCountDown";
import * as Game from "./module/Game";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Authentication,
    GameRule,
    Game,
    BaseCountDown,
    notification,
    LoadAssets,
    LoadImages,
    LoadAudios,
  },
});
