import store from "@/store/store";
import router from "@/router";
import { EventBus } from "@/event/EventBus";

function ErrorHandler(error) {
  console.log("Error: ", error);
  if (error.code) {
    if (error.code == "ERR_NETWORK") {
      store.state.Authentication.noConnection = true;
      EventBus.$emit("stopService");
      // console.log("hh: ", store.state.Authentication.noConnection);
      const notification = {
        type: "error",
        message:
          "It seems there is network issue please check your connection!",
      };
      store.dispatch("notification/add", notification);
    } else if (error.code == "ECONNABORTED") {
      const notification = {
        type: "error",
        message: "Please try again!",
      };
      store.dispatch("notification/add", notification);
    } else if (error.code == "ERR_BAD_REQUEST") {
      if (error.response.data.error.statusCode == 401) {
        localStorage.clear();
        EventBus.$emit("stopService");
        router.push("/");
        const notification = {
          type: "error",
          message: error.response.data.message,
        };
        store.dispatch("notification/add", notification);
        return false;
      } else {
        localStorage.clear();
        router.push("/");
        const notification = {
          type: "error",
          message: error.response.data.message,
        };
        store.dispatch("notification/add", notification);
        return false;
      }
    } else {
      EventBus.$emit("stopService");
      localStorage.clear();
      router.push("/");
      const notification = {
        type: "error",
        message: error.message,
      };
      store.dispatch("notification/add", notification);
      return false;
    }
  }
}

export default ErrorHandler;
