<template>
  <div class="w-full flex justify-center p-1">
    <div class="">
      <div class="text-center text-lg text-red mb-3" style="">
        <p>{{ message }}</p>
      </div>
      <div class="flex justify-center  w-1/2 space-x-2">
        <BaseButton
          buttonClass="w-full"
          class="cursor-pointer mb-2 bg-blue-500 w-full text-center text-white"
          @click="No"
          >No</BaseButton
        >
        <BaseButton
          buttonClass="w-full"
          class="cursor-pointer mb-2 bg-hardred w-full  text-center text-white"
          @click="Yes"
          >Yes</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
    },
    Yes: {
      type: Function,
      required: true,
    },
  },
  methods: {
    No() {
      this.$store.dispatch('modals/confirmationmodal', false)
    },
  },
}
</script>

<style></style>
